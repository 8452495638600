import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrSendEmailView',
  computed: {
    ...mapState('email', ['emails', 'emailPreview']),
    ...mapState('registration', ['registration'])
  }
})

export default class GtrSendEmailView extends GtrSuper {
  data () {
    return {
      loading: false,
      submitting: false,
      to: '',
      cc: '',
      bcc: '',
      showEmailDialog: false,
      event_uuid: this.$route.params.event_uuid,
      registration_uuid: this.$route.params.attendee_uuid,
      current_registration: {},
      search: '',
      emails_name_array: [],
      email_name: '',
      _email_data: ''
    }
  }

  async mounted () {
    this.clearEmailPreview()
    await this.fetchEmails()
    await this.fetchRegistration()
  }

  async fetchEmails () {
    await this.$store.dispatch('email/fetchEmails', this.$route.params.event_uuid)
  }

  @Watch('registration', { immediate: true })
  onRegistrationValueChange (newVal: any) {
    this.$data.current_registration = newVal
  }

  @Watch('emails', { immediate: true })
  onEmailsValueChange (newVal: any) {
    newVal.forEach((email: any) => {
      const name = this.handleEmailName(email.key)
      this.$data.emails_name_array.push({
        text: name,
        value: email.key
      })
    })
  }

  get usableEmails (): any[] {
    const filteredEmails = this.$data.emails_name_array.filter(email => email.value !== 'admin_cancellation_notification' && email.value !== 'admin_registration_notification')
    return filteredEmails.sort((a, b) => a.text >= b.text ? 1 : -1)
  }

  onSelectedEmailValueChange () {
    this.$data.loading = true
    if (this.$data.email_name) {
      this.$store.dispatch('email/emailPreviewRegistration', {
        event_uuid: this.$route.params.event_uuid,
        registration_uuid: this.$route.params.attendee_uuid,
        email_name: this.$data.email_name
      })
    }
    this.$data.loading = false
  }

  @Watch('emailPreview', { immediate: true })
  onEmailPreviewValueChange (newVal: any) {
    this.$data.loading = true
    this.$data._email_data = newVal.email_data
    this.$data.to = this.printEmail(newVal.to)
    this.$data.cc = this.printEmail(newVal.cc)
    this.$data.bcc = this.printEmail(newVal.bcc)
    this.$data.loading = false
  }

  printEmail (email) {
    let print = ''
    for (const i in email) {
      if (print !== '') {
        print += ', '
      }
      print += email[i].address
    }
    return print
  }

  async sendEmailToOneRegistration () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        company_uuid: this.$route.params.company_uuid,
        registration_uuid: this.$route.params.attendee_uuid,
        email_name: this.$data.email_name,
        data: {
          event_uuid: this.$route.params.event_uuid,
          company_uuid: this.$route.params.company_uuid,
          registration_uuid: this.$route.params.attendee_uuid,
          email_name: this.$data.email_name,
          to: this.$data.to != null ? this.$data.to.split(',') : '',
          cc: this.$data.cc != null ? this.$data.cc.split(',') : '',
          bcc: this.$data.bcc != null ? this.$data.bcc.split(',') : ''
        }
      }
      await this.$store.dispatch('email/sendEmailToOneRegistration', payload)
      Container.get(Notification).success('Email successfully sent.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchRegistration () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        participant_uuid: this.$route.params.attendee_uuid,
        queryParam: ''
      }
      await this.$store.dispatch('registration/getRegistration', payload)
      this.clearEmailPreview()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private clearEmailPreview () {
    this.$data.to = ''
    this.$data.cc = ''
    this.$data.bcc = ''
    this.$data._email_data = ''
  }
}
